







































































import { Component, Vue } from "vue-property-decorator";
import firebase from "firebase";

@Component
export default class Login extends Vue {
  clearText = false;
  errors = [];
  success = false;
  email = "";
  password = "";

  isActiveUser = true;

  formLostPassword(e) {
    this.errors = [];
    this.success = false;
    e.preventDefault();
    const okay = this.checkForm();
    if (okay) {
      firebase
        .auth()
        .sendPasswordResetEmail(this.email)
        .then(() => {
          // Password reset email sent.
          this.success = true;
          this.email = "";
        })
        .catch(error => {
          let message = error.message;
          if (error.code == "auth/user-not-found") {
            message = "There is no email set up. Please register";
          }
          this.errors.push(error.code);
          this.errors.push(message);
        });
      return null;
    }
  }

  get hotelData() {
    return this.$store.state.hotelInfo;
  }

  redirectPage(guestKey, callbackGuestKey) {
    if (callbackGuestKey) {
      this.$router.push(`/guest/${callbackGuestKey}`);
    } else {
      if (guestKey) localStorage.removeItem("guestKey");
      this.$router.push("/");
    }
  }

  createUserRedirect(data, guestKey) {
    if (data.error) this.errors.push(data.error);
    if (data.info) {
      firebase
        .auth()
        .createUserWithEmailAndPassword(this.email, this.password)
        .then(userCredential => {
          this.$store.commit("setAuthProfile", userCredential.user);
          this.redirectPage(guestKey, data.info.guestKey);
        })
        .catch(error => {
          let message = error.message;
          if (error.code == "auth/user-not-found") {
            this.isActiveUser = false;
            message = "There is no email set up. Please register";
          }
          this.errors.push(error.code);
          this.errors.push(message);
        });
      return null;
    }
  }

  checkForm() {
    if (this.email) {
      return true;
    }
    this.errors = [];
    if (!this.email) {
      this.errors.push("Email required");
    }
  }
}
